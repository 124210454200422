









































































































































































import { Component, Prop } from 'vue-property-decorator';
import ModelConfig from '@/models/ModelConfig';
import ModelConfigService from '@/services/ModelConfigService';
import ModelElementType from '@/models/ModelElementType';
import { hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import { exportConfig } from '@/views/configs/ConfigExporter';
import { Toasts } from '@/mixins/ToastMixins';
import { mixins } from 'vue-class-component';
import { slugify } from '@/serializer/helpers';
import SVGHelper from '@/serializer/SVGHelper';

@Component
export default class ModelConfigDetails extends mixins(Toasts) {
  @Prop({ required: true, default: '' })
  private readonly type!: string;
  @Prop({ required: true, default: '' })
  private readonly version!: string;

  protected readonly breadcrumbs = [
    { text: 'Home', to: '/' },
    { text: 'Model Configs', to: '/configs' },
    { text: 'Details', active: true },
  ];

  protected currentConfig: ModelConfig | null = null;

  mounted(): void {
    if (hasPermission(PossibleAction.CAN_GET_CONFIG)) {
      this.$on('finished-delete-config', () => {
        this.$router.replace({ path: '/configs' });
      });

      this.loadConfig(this.type, this.version);
    } else {
      this.$router.replace({ path: '/configs' }).finally(() => {
        this.showToast('Action denied', 'You do not have the required rights.', 'danger');
      });
    }
  }

  beforeDestroy(): void {
    this.$off('finished-delete-config');
  }

  protected loadConfig(modelType: string, version: string): void {
    if (hasPermission(PossibleAction.CAN_GET_CONFIG)) {
      this.$root.$data.loading = true;
      ModelConfigService.getByModelTypeAndVersion(modelType, version)
        .then((modelConfig) => {
          this.currentConfig = modelConfig;
        })
        .catch((backendError) => {
          let msg,
            title = 'Failed to load';
          if (backendError.response === undefined) {
            msg = 'Could not find config: ' + this.type + ' ' + this.version;
          } else if (backendError.response.status === 403) {
            title = 'Action denied';
            msg = 'You do not have the required rights.';
          } else {
            msg = 'Oops, something failed: ' + backendError.response.status;
          }
          this.$router.replace({ path: '/configs' }).finally(() => {
            this.showToast(title, msg, 'danger');
          });
        })
        .finally(() => (this.$root.$data.loading = false));
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected getDisplayOfElement(element: ModelElementType): string {
    let displaySVG: string = element.display;
    element.attributeTypes.forEach((attr) => {
      if (attr.variableName.toLowerCase() === 'height' || attr.variableName.toLowerCase() === 'width') {
        displaySVG = displaySVG.split('{{attribute_' + attr.variableName + '}}').join('100%');
      } else {
        displaySVG = displaySVG.split('{{attribute_' + attr.variableName + '}}').join(attr.variableName);
      }

      // replace edge defs marker id
      displaySVG = SVGHelper.createDynamicEdgePathIds(displaySVG);
    });
    return displaySVG;
  }

  protected getDisplayStringOfElement(variableName: string, display: string): string {
    let displaySVG;
    if (variableName.toLowerCase() === 'height' || variableName.toLowerCase() === 'width') {
      displaySVG = display.split('{{' + variableName + '}}').join('100%');
    } else if (variableName === 'name') {
      displaySVG = display.split('{{' + variableName + '}}').join(variableName);
    } else {
      displaySVG = display.split('{{attribute_' + variableName + '}}').join(variableName);
    }
    return displaySVG;
  }

  protected deleteConfig(config: ModelConfig): void {
    if (hasPermission(PossibleAction.CAN_DELETE_CONFIG)) {
      this.$root.$emit('delete-config', { config, target: this });
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected exportConfig(config: ModelConfig): void {
    exportConfig(config);
  }

  protected changeConfigState(config: ModelConfig, active: boolean): void {
    if (hasPermission(PossibleAction.CAN_UPDATE_CONFIG)) {
      this.$root.$emit('change-config-state', { config, active });
    } else {
      this.showToast('Action denied', 'You do not have the required rights.', 'danger');
    }
  }

  protected slugify = (text: string): string => slugify(text);
}
